
import { defineComponent, ref, onMounted, computed, watch, Ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import { Edit, Check, Delete } from "@element-plus/icons-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { event } from "vue-gtag";

interface NewAddressData {
  module: string;
  trimestre: string;
}
interface IStudent {
  _id: string;
  BirthDate: string;
  firstName: string;
  lastName: string;
  gender: string;
  photo: string;
}
interface ISubject {
  _id: string;
  name: string;
  status: string;
}
interface SubjectInfo {
  sousModule?: string;
}

interface SubjectsInfo {
  [subjectId: string]: SubjectInfo;
}
interface IModule {
  _id: string;
  name: string;
  status: string;
  subjects: ISubject[];
  subjectsInfo: SubjectsInfo;
  sousModules?: string[];
}

interface Appreciation {
  _id: string;
  appreciation: string;
  schoolarYear: string;
  student: string;
  module: string;
  moduleSpecific?: string;
  moduleVerySpecific?: string;
  trimester: number;
  type?: string;
  moduleVerySpecificName?: string;
}

export default defineComponent({
  name: "appreciationsVerySpec",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const filter = ref<string>("");
    const list = ref<IStudent[]>([]);
    const listModule = ref<IModule[]>([]);
    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + "/");

    const sy = window.localStorage.getItem("activeSchoolarYear");
    const trimester = JwtService.getTrimester();
    const loading = ref(true);

    const targetData = ref<NewAddressData>({
      module: "",
      trimestre: trimester || "1",
    });
    const rules = ref({
      matiere: [
        {
          required: true,
          message: "Please select Activity zone",
          trigger: "change",
        },
      ],
      trimestre: [
        {
          required: true,
          message: "Please select Activity zone",
          trigger: "change",
        },
      ],
    });
    const filtredStudents = computed(() => {
      if (filter.value) {
        return list.value.filter((s: IStudent) => {
          let name: string = s.firstName + " " + s.lastName;
          return name.includes(filter.value.toString());
        });
      }
      return list.value;
    });

    const appreciations = ref<Appreciation[]>([]);

    const selectedStudent = ref("");
    const currentClassroomLevel = ref<number>();
    const currentAppreciation = ref("");
    const saveLoading = ref(false);
    const moduleMoyennes = ref<any[]>([]);
    const manualAppreciation = ref<boolean>(false);
    const appreciationOptions = ref<any[]>([]);

    const getAppreciation = (studentID: string) => {
      let moduleName = listModule.value?.find(
        (n) => n._id == targetData.value.module
      )?.name;
      console.log(moduleName);

      const appreciation = appreciations.value.find(
        (a: Appreciation) =>
          a.student == studentID &&
          a.moduleVerySpecificName == moduleName &&
          String(a.trimester) == String(targetData.value.trimestre)
      );
      if (appreciation) return appreciation.appreciation;
      return "";
    };

    const getMoyenne = (studentID: string) => {
      const student = moduleMoyennes.value.find((s) => s._id == studentID);
      if (student) {
        let moduleName = listModule.value?.find(
          (n) => n._id == targetData.value.module
        )?.name;
        const module = student.modules.find(
          (m: IModule) => m.name == moduleName
        );
        if (module) return module.moyenne;
      }
      return "--";
    };

    const getSubjects = (studentID: string) => {
      const student = moduleMoyennes.value.find((s) => s._id == studentID);
      if (student) {
        let moduleName = listModule.value?.find(
          (n) => n._id == targetData.value.module
        )?.name;
        const module = student.modules.find(
          (m: IModule) => m.name == moduleName
        );
        if (module) return module.subjects;
      }
      return [];
    };

    const handleEdit = (studentID: string) => {
      selectedStudent.value = studentID;
      currentAppreciation.value = getAppreciation(studentID);
    };

    const handleCloseEdit = () => {
      selectedStudent.value = "";
      currentAppreciation.value = "";
    };

    const deleteLoading = ref(false);

    const handleDelete = (studentID) => {
      event("Delete Appreciation specific", {
        event_category: "Appreciations",
        event_label: "Classe profile",
        value: 1,
      });

      const appreciation = appreciations.value.find(
        (a: Appreciation) =>
          a.student == studentID &&
          a.moduleVerySpecific == targetData.value.module
      );

      deleteLoading.value = true;
      ApiService.delete("/appreciation/" + appreciation?._id)
        .then(() => {
          appreciations.value = appreciations.value.filter(
            (app) => app._id != appreciation?._id
          );
        })
        .finally(() => {
          deleteLoading.value = false;

          Swal.fire({
            text: t("appreciation.delete"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    };

    const handleSave = async (studentID: string) => {
      event("Save Appreciation specific", {
        event_category: "Appreciations",
        event_label: "Classe profile",
        value: 1,
      });
      console.log("targetData.value.module 1", listModule.value);

      saveLoading.value = true;

      const teacherApp = teacherAppreciations.value.find(
        (el) => currentAppreciation.value == el.appreciation
      );
      if (!teacherApp && teacherAppreciations.value.length < 15)
        await ApiService.put("/teacherAppreciation", {
          data: {
            teacher: store.getters.currentUser._id as string,
            appreciation: currentAppreciation.value,
          },
        })
          .then(({ data }) => {
            teacherAppreciations.value.push(data);
          })
          .catch((e) => console.log(e));

      const appreciation = appreciations.value.find(
        (a: Appreciation) =>
          a.student == studentID &&
          a.moduleVerySpecific == targetData.value.module &&
          a.trimester == Number(targetData.value.trimestre)
      );
      //add sous module field to divide the bulletin section in PDF
      let moduleDetails = Object.assign(
        {},
        listModule.value.find((m) => m._id == targetData.value.module)
      );
      if (
        currentClassroomLevel.value == 5 ||
        currentClassroomLevel.value == 6
      ) {
        if (moduleDetails) {
          moduleDetails.sousModules = [];
          moduleDetails?.subjects?.forEach((s) => {
            if (moduleDetails?.subjectsInfo[s._id].sousModule)
              moduleDetails.sousModules?.push(
                moduleDetails?.subjectsInfo[s._id].sousModule || "other"
              );
            moduleDetails.sousModules = moduleDetails.sousModules?.filter(
              (value, index, self) => self.indexOf(value) === index
            );
          });
        }
      }

      if (!appreciation) {
        let appData: any = {
          moduleVerySpecific: targetData.value.module,
          student: selectedStudent.value,
          appreciation: currentAppreciation.value,
          trimester: targetData.value.trimestre,
          classroom: route.params.id,
          type: "verySpecific",
        };
        if (moduleDetails.sousModules)
          appData.sousModules = moduleDetails.sousModules;
        ApiService.put("/appreciation/", {
          data: appData,
        })
          .then(({ data }) => {
            appreciations.value.push(data);
          })
          .finally(() => {
            saveLoading.value = false;
            handleCloseEdit();

            Swal.fire({
              text: t("appreciation.saved"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } else {
        let appData: any = {
          appreciation: currentAppreciation.value,
        };
        if (moduleDetails.sousModules)
          appData.sousModules = moduleDetails.sousModules;
        ApiService.patch("/appreciation/" + appreciation._id, {
          data: appData,
        })
          .then(() => {
            appreciation.appreciation = currentAppreciation.value;
          })
          .finally(() => {
            saveLoading.value = false;
            handleCloseEdit();

            Swal.fire({
              text: t("appreciation.saved"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      }
    };

    onMounted(async () => {
      //GET STUDENTS
      ApiService.setHeader();
      let match = {};
      match[`schoolarYearsHistory.${sy}`] = route.params.id;
      await ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: { $arrayElemAt: ["$classRoom._id", 0] },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              teachers: {
                $arrayElemAt: ["$classRoom.teachers", 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              firstNameLower: { $toLower: "$firstName" },
            },
          },
          {
            $sort: {
              firstNameLower: 1,
            },
          },
        ],
      }).then(({ data }) => {
        list.value = data;
      });

      ApiService.post("/appreciation/filter", {
        query: {
          schoolarYear: sy,
          trimester: targetData.value.trimestre || "1",
          classroom: route.params.id,
          teacher: store.getters.currentUser._id,
          type: "verySpecific",
        },
      }).then(({ data }) => {
        data.forEach((el: Appreciation) => {
          appreciations.value.push(el);
        });
      });

      //GET TEACHER
      ApiService.setHeader();
      await ApiService.post("/teacher/classRommDetails", {
        query: {
          teacher: store.getters.currentUser._id,
        },
      })
        .then(({ data }) => {
          let listSubject: any[] = [];
          const classroom = data.find(
            (el) => el.classRoom._id == route.params.id
          );
          if (classroom) {
            listSubject = classroom.subjects;

            classroom.modulesSpecific.map((m) => {
              listSubject.push(...m.subjects);
            });
            classroom.modules.map((m) => {
              listSubject.push(...m.subjects);
            });

            //remove duplicated
            listSubject = listSubject.filter(
              (subject, index) =>
                listSubject.findIndex((s) => s._id == subject._id) === index &&
                subject.status !== "inactive"
            );
            currentClassroomLevel.value = classroom.classRoom.level;
            ApiService.post("/moduleVerySpecific/filter", {
              query: {
                status: "active",
                level: classroom.classRoom.level,
                trimester: targetData.value.trimestre,
              },
            })
              .then((res) => {
                res.data.map((module) => {
                  module.subjects = module.subjects.filter((s) =>
                    listSubject.find((ls) => ls._id == s._id)
                  );
                });
                listModule.value = res.data.filter(
                  (m) => m.subjects.length > 0
                );
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => console.log(e));

      await ApiService.get(
        `/markvspecific/moduleMoyennes/${route.params.id}/${
          targetData.value.trimestre || "1"
        }`
      )
        .then(({ data }) => {
          moduleMoyennes.value = data;
        })
        .catch((e) => console.log(e));

      await ApiService.post(
        "/buildings/filter",
        {
          query: {
            dbName: store.getters.serverConfigUrl?.dbName,
          },
        }, //dbName:"school2"
        {
          headers: { db: "Root" },
        }
      ).then(({ data }) => {
        const _data = data[0];
        manualAppreciation.value = _data.manualAppreciation;
        appreciationOptions.value = [..._data.appreciationOptions];
        loading.value = false;
      });
    });

    watch(
      targetData,
      async () => {
        list.value = [];
        loading.value = true;

        const match = {};
        match[`schoolarYearsHistory.${sy}`] = route.params.id;
        ApiService.post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: "$schoolarYearsHistory." + sy,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classRoom",
              },
            },
            {
              $project: {
                _id: 1,
                classRoom: {
                  $arrayElemAt: ["$classRoom._id", 0],
                },
                classRoomName: {
                  $arrayElemAt: ["$classRoom.name", 0],
                },
                teachers: {
                  $arrayElemAt: ["$classRoom.teachers", 0],
                },
                BirthDate: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                photo: 1,
                firstNameLower: { $toLower: "$firstName" },
              },
            },
            {
              $sort: {
                firstNameLower: 1,
              },
            },
          ],
        }).then(({ data }) => {
          list.value = data;
        });

        await ApiService.post("/appreciation/filter", {
          query: {
            schoolarYear: sy,
            trimester: targetData.value.trimestre || "1",
            classroom: route.params.id,
            teacher: store.getters.currentUser._id,
            type: "verySpecific",
          },
        }).then(({ data }) => {
          data.forEach((el: Appreciation) => {
            appreciations.value.push(el);
          });
        });

        ApiService.get(
          `/markvspecific/moduleMoyennes/${route.params.id}/${
            targetData.value.trimestre || "1"
          }`
        )
          .then(({ data }) => {
            moduleMoyennes.value = data;
          })
          .catch((e) => console.log(e))
          .finally(() => {
            loading.value = false;
          });
      },
      { deep: true }
    );

    const dialogFormVisible = ref(false);
    const teacherApreciationText = ref("");
    const loadingAddTeacherAppreciation = ref(false);
    const teacherAppreciations = ref<
      { _id: string; appreciation: string; teacher: string }[]
    >([]);
    const loadingEditTeacherAppreciation = ref(false);
    const selectedTeacherAppreciation = ref<any>("");
    const currentTeacherAppreciation = ref<any>("");

    ApiService.post("/teacherAppreciation/filter", {
      query: {
        teacher: store.getters.currentUser._id,
      },
    }).then(({ data }) => {
      data.forEach((el) => {
        teacherAppreciations.value.push(el);
      });
    });

    const handleAddAppTeacher = () => {
      event("Add appreciation option", {
        event_category: "Appreciation options",
        event_label: "Classe profile",
        value: 1,
      });

      loadingAddTeacherAppreciation.value = true;
      ApiService.put("/teacherAppreciation", {
        data: {
          teacher: store.getters.currentUser._id as string,
          appreciation: teacherApreciationText.value,
        },
      })
        .then(({ data }) => {
          teacherAppreciations.value.push(data);
          teacherApreciationText.value = "";
        })
        .catch((e) => console.log(e))
        .finally(() => {
          loadingAddTeacherAppreciation.value = false;
        });
      teacherApreciationText.value = "";
    };

    const openEditTeacherAppreciation = (record) => {
      currentTeacherAppreciation.value = record.appreciation;
      selectedTeacherAppreciation.value = record._id;
    };

    const handleEditAppTeacher = (record) => {
      event("Edit appreciation option", {
        event_category: "Appreciation options",
        event_label: "Classe profile",
        value: 1,
      });

      loadingEditTeacherAppreciation.value = true;
      ApiService.patch("/teacherAppreciation/" + record._id, {
        data: {
          appreciation: currentTeacherAppreciation.value,
        },
      })
        .then(() => {
          record.appreciation = currentTeacherAppreciation.value;
        })
        .catch((e) => console.log(e))
        .finally(() => {
          loadingEditTeacherAppreciation.value = false;
          selectedTeacherAppreciation.value = "";
          currentTeacherAppreciation.value = "";
        });
    };

    const loadingDeleteTeacherAppreciation = ref(false);
    const handleDeleteAppTeacher = (record) => {
      event("Delete appreciation option", {
        event_category: "Appreciation options",
        event_label: "Classe profile",
        value: 1,
      });

      loadingDeleteTeacherAppreciation.value = true;
      ApiService.delete("/teacherAppreciation/" + record._id)
        .then(() => {
          teacherAppreciations.value = teacherAppreciations.value.filter(
            (el: any) => el._id !== record._id
          );
        })
        .catch((e) => console.log(e))
        .finally(() => {
          loadingDeleteTeacherAppreciation.value = false;
        });
    };

    const onFliterSelect = (value) => {
      if (value) currentAppreciation.value = value;
    };

    return {
      openEditTeacherAppreciation,
      currentTeacherAppreciation,
      selectedTeacherAppreciation,
      loadingEditTeacherAppreciation,
      loadingDeleteTeacherAppreciation,
      handleAddAppTeacher,
      teacherAppreciations,
      teacherApreciationText,
      loadingAddTeacherAppreciation,
      handleEditAppTeacher,
      handleDeleteAppTeacher,
      dialogFormVisible,
      saveLoading,
      currentAppreciation,
      handleEdit,
      handleSave,
      handleDelete,
      deleteLoading,
      handleCloseEdit,
      selectedStudent,
      getAppreciation,
      t,
      targetData,
      rules,
      list,
      onMounted,
      listModule,
      filter,
      filtredStudents,
      apiUrl,
      Check,
      Edit,
      Delete,
      loading,
      getMoyenne,
      manualAppreciation,
      appreciationOptions,
      onFliterSelect,
      getSubjects,
    };
  },
});
